<script lang="ts" setup>
const isActive = defineModel({
  default: false
})
</script>

<template>
  <SwitchRoot
    v-model="isActive"
    class="w-48px h-28px p-3px relative flex flex-shrink-0 rounded-full cursor-default bg-neutral-200 data-[state=checked]:bg-primary-500 disabled:bg-primary-300 disabled:pointer-events-none"
  >
    <SwitchThumb
      class="size-5 block my-auto bg-white shadow rounded-full transition-duration-200 transition-transform transition-ease will-change-transform translate-x-0 data-[state=checked]:translate-x-[22px]"
    />
  </SwitchRoot>
</template>
